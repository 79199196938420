@import '@lansforsakringar/fonts/index.css';
@import '@lansforsakringar/components/dist/lfui/style.css';

.logo {
  height: 62px;
  margin-left: -12px;
}

.header {
  z-index: 1000;
  position: relative;
  box-shadow: inset 0px -2px 0px #e0e0e0;

  .user-menu {
    position: relative;
    margin-right: 16px;
    user-select: none;

    .user-menu-dropdown {
      min-width: 200px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--blue);
      background-color: var(--tint);
      padding: 0.25rem 0.5rem;

      svg {
        fill: var(--blue);
      }

      &:hover {
        color: rgb(0, 47, 84);

        svg {
          fill: rgb(0, 47, 84);
        }
      }

      .open {
        transform: rotate(180deg);
      }
    }

    .user-menu-content {
      min-width: 248px;
      position: absolute;
      left: 0;
      top: 45px;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 2px,
        rgba(0, 0, 0, 0.05) 0px 0px 1px 1px;

      h2 {
        padding: 1rem 1.5rem !important;
      }

      .user-menu-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--blue);
        width: 100%;
        padding: 0.8rem 1.5rem !important;

        svg {
          fill: var(--blue);
        }

        &:hover {
          color: rgb(0, 47, 84);
          background-color: var(--cloud);

          svg {
            fill: rgb(0, 47, 84);
          }
        }
      }
    }
  }
}

.menu {
  z-index: 900;
  position: relative;

  &.scrolled-down {
    transform: translateY(-200%);
    transition: all 0.25s ease-in-out;
  }
  &.scrolled-up {
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
  }

  .nav-item {
    &:hover,
    &.active {
      background-color: var(--cloud);

      a {
        padding-bottom: 0.675rem !important;
      }
    }
  }

  .contact-tab {
    position: relative;
    user-select: none;

    &:hover,
    &.active {
      background-color: var(--cloud);
    }

    &.active {
      .contact-tab-btn-wrapper {
        margin-bottom: -2px;
      }
    }

    .contact-tab-btn {
      height: 100%;
      padding: 0 0.825rem;
      display: flex;
      align-items: center;

      svg {
        fill: var(--navy);
      }
    }
  }
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 500;
  background-color: #222;
  opacity: 0.45;
}

.footer-logo {
  height: 36px;
}

.login-wrapper {
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;

  .login-warning {
    padding: 0.75rem 1rem;

    svg {
      margin-right: 0.625rem;
    }
  }

  .login-image {
    background: url('../assets/login.png') left center / cover;
  }

  .login-terms {
    font-size: 10px;
    line-height: 11.5px;
    color: var(--gray);
  }
}

.load-more-link {
  color: var(--blue);
  text-decoration: underline;

  &:hover {
    color: #002f54 !important;
  }
}

.has-danger .select-toggle {
  border-color: var(--red);
}

.auction-data {
  div:nth-child(4n-2),
  div:nth-child(4n-3) {
    background: var(--tint);
  }
}

.contact-data div:nth-child(odd) {
  background: var(--tint);
}

.object-data {
  div:nth-child(4n),
  div:nth-child(4n-1) {
    background: var(--tint);
  }
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.auction-ordering-wrapper {
  .descending {
    transform: rotate(180deg);
  }
  .auction-order-dropdown {
    background-color: var(--white);
    top: 100%;
    z-index: 800;
    margin-top: 0.875rem;

    span {
      min-height: 40px;
      padding: 0.563rem 0.75rem;
      color: var(--black);
      white-space: pre-wrap;
      user-select: none;
      background-color: #fbfbfb;
      border-bottom: 1px solid #eee;
      outline-offset: -4px !important;

      &:hover {
        color: var(--blue);
        background-color: var(--white);
      }
    }
  }
}

.dropdown-select {
  width: 220px;
  background-color: var(--white);
  z-index: 800;
  top: 100%;
  right: 0;
  margin-top: 0.875rem;

  span {
    min-height: 40px;
    padding: 0.563rem 0.75rem;
    color: var(--black);
    white-space: pre-wrap;
    user-select: none;
    background-color: #fbfbfb;
    border-bottom: 1px solid #eee;
    outline-offset: -4px !important;

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }
}

.alert-auction {
  position: absolute;
  top: 24px;
  right: 24px;

  &.active {
    svg {
      fill: var(--blue);
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: var(--sky);
    }
  }

  svg {
    fill: #8c8f90;
    height: 32px;
  }
}

.category-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.dropdown-select {
  width: 220px;
  background-color: var(--white);
  z-index: 800;
  top: 100%;
  right: 0;
  margin-top: 0.875rem;

  span {
    min-height: 40px;
    padding: 0.563rem 0.75rem;
    color: var(--black);
    white-space: pre-wrap;
    user-select: none;
    background-color: #fbfbfb;
    border-bottom: 1px solid #eee;
    outline-offset: -4px !important;

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }
}

.my-auctions-bid-container {
  min-width: 124px;
  border: 1px solid;
}

.bg-yellow {
  background-color: #ffff00;
}

.auction-info-col {
  max-width: 200px;
  max-height: 50px;
}
